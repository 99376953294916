<template>
  <!-- <div ref="echart" v-resize="onResize" style="width: inherit; height: inherit"/> -->
  <!-- <canvas ref="canvas" id="canvas" class="canvas"></canvas> -->
  <div>
    <!-- <div v-if="!show" style="padding: 200px">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div> -->
    <vue-word-cloud
      style="height: calc(80vh); width: 100%"
      :words="words"
      :color="
        ([, weight]) =>
          weight > 10000 ? 'DeepPink' : weight > 5000 ? 'RoyalBlue' : 'Indigo'
      "
      font-family="Roboto"
    />
  </div>
</template>
<script>
// import echarts from 'echarts/';
// import * as echarts from 'echarts/dist/echarts.js';
// import 'echarts-wordcloud';
// import 'wordcloud';

// import options from './data/wordcloud';

// const list = [['foo', 12], ['bar', 6]];
import VueWordCloud from 'vuewordcloud';
import wordsDB from './data/wordcloud.json';

const words = wordsDB.map((item) => [item.word, item.nentry]).slice(0, 90);
// console.log('words',words);

// console.log('echartWordCLoud', echartWordCLoud);
export default {
  components: {
    'vue-word-cloud': VueWordCloud,
  },
  props: ['module', 'chartId'],
  data() {
    return {
      chart: null,
      words,
      show: false,
    };
  },
  computed: {},
  mounted() {
    // this.chart = echarts.init(this.$refs.echart);
    // window.WordCloud(this.$refs.canvas, { list: list } );
    // this.chart.setOption(options);
    const that = this;
    const timeout = setTimeout(() => {
      that.show = true;
      clearTimeout(timeout);
    }, 200);
  },
  methods: {
    rendered() {},
    onResize() {
      // console.log("resized");
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};
</script>
